@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 17px;
  font-family: Quicksand, sans-serif;
}

* {
  font-family: Quicksand, sans-serif !important;
}

@import './app/styles/material';
