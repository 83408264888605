@use '@angular/material' as mat;

@include mat.core();

$primary-palette-colors: (
  50: #332f3d,
  100: #51bdbf,
  200: #f2f2f2,
  300: #e8e8e8,
  400: #c6c6c6,
  500: #a8a8a8,
  600: #7e7e7e,
  700: #6a6a6a,
  800: #6a6a6a,
  900: #282828,
  contrast: (
    50: #f6f6f6,
    100: #f6f6f6,
    200: #f6f6f6,
    300: #f6f6f6,
    400: #f6f6f6,
    500: #f6f6f6,
    600: #282828,
    700: #282828,
    800: #282828,
    900: #282828,
  ),
);

$app-primary: mat.define-palette($primary-palette-colors, 50);
$app-accent: mat.define-palette($primary-palette-colors, 100);

$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
  ),
  typography: mat.define-typography-config(),
  density: 0,
));

@include mat.all-component-themes($app-theme);

.mdc-button {
  border-radius: 0 !important;

  background: #332f3d !important;
  color: #ffffff !important;
  padding: 15px 33px !important;

  font-size: 13px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  letter-spacing: 0.8px !important;

  &[disabled] {
    color: #6e6777 !important;
    background: #afadb0 !important;
  }
}

.mat-mdc-form-field .mdc-notched-outline__notch {
  border-right: 0px !important;
}

.textarea-fill {
  .mat-mdc-form-field-flex {
    align-items: stretch !important;
    min-height: 100%;

    textarea {
      min-height: 100%;
    }
  }
}

.mdc-text-field--outlined {
  --mdc-outlined-text-field-container-shape: 1px !important;
}
